import { defineStore } from "pinia";
import { About } from "@/api/about";

export const useAppStore = defineStore("app", {
    state: () => {
        return {
            configuration: {
                google: {
                    maps: {
                        apiKey: "",
                    },
                },
                stripe: {
                    publishableKey: "",
                },
                sentry: {
                    dsn: "",
                    environment: "",
                    debug: false,
                    release: "",
                    ip: "",
                },
            },
            version: "",
        };
    },
    actions: {
        async load(about: About) {
            const conf = this.configuration;
            conf.sentry.dsn = about.configuration.sentry.dsn;
            conf.sentry.environment = about.configuration.sentry.environment;
            conf.sentry.debug = about.configuration.sentry.debug;
            conf.sentry.release = about.configuration.sentry.release;
            conf.sentry.ip = about.configuration.sentry.ip;
            conf.google.maps.apiKey = about.configuration.googleMaps.apiKey;
            conf.stripe.publishableKey =
                about.configuration.stripe.publishableKey;
            this.version = about.version;
        },
    },
});
