import axios from "axios";

export interface About {
    configuration: {
        googleMaps: {
            apiKey: string;
        };
        stripe: {
            publishableKey: string;
        };
        sentry: {
            dsn: string;
            environment: string;
            release: string;
            debug: boolean;
            ip: string;
        };
    };
    version: string;
}

export type AboutApi = {
    get(): Promise<About>;
};

export const aboutApi: AboutApi = {
    async get(): Promise<About> {
        const { data } = await axios.get<About>(`/api/v0/about`);
        return data;
    },
};
