
import "@fontsource/poppins";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
    setup() {
        const route = useRoute();
        const drawer = ref(false);
        const resizeObserver = new ResizeObserver((entries) => {
            window.parent?.postMessage(
                {
                    type: "resize",
                    height: document.querySelector("body")?.scrollHeight,
                },
                "*"
            );
        });
        resizeObserver.observe(document.querySelector("body")!);
        const isPublic = computed(() => {
            return route.fullPath.startsWith("/public");
        });
        return {
            drawer,
            isPublic,
        };
    },
});
